@import url('https://fonts.googleapis.com/css2?family=Fanwood+Text&display=swap');
body {
  margin: 0;
  font-family: 'Fanwood Text', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  
}
.black-bg{
  min-height: 100vh;
  color: white;
  background: linear-gradient(90deg, #B79891 0%, #94716B 100%);
}
.center-text{
  text-align: center;
}
a{
  text-decoration: none;
  color: inherit;
}